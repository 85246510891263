import React from 'react'
import { graphql, Link } from 'gatsby'
import { PortableText } from "@portabletext/react"
import Serializers from '../components/serializers'
import {GatsbyImage} from 'gatsby-plugin-image'
import styled from "@emotion/styled"

import Layout from '../components/layout'

const _ = require("lodash")

export const pageQuery = graphql`
query ProjectQuery ($id: String!) {
    project: sanityProject(id: { eq: $id }) {
          id
          title
          _rawDescription(resolveReferences: {maxDepth: 5})
          image {
            _key
            caption
            _rawDescription(resolveReferences: {maxDepth: 5})
            image {
              asset {
                gatsbyImageData
              }
            }
          }
    }
  }
  `

const Textwrap = styled.div`
    margin: 0 auto 24px auto;
`

const ProjectTemplate = ({ pageContext, data }) => {
    const project = data && data.project

    const { next } = pageContext
    const { previous } = pageContext

    return (
        <Layout>
            {previous && <div><Link to={`/album/${_.kebabCase(previous.title)}`}>{previous.title}</Link></div>}
            {next && <div><Link to={`/album/${_.kebabCase(next.title)}`}>{next.title}</Link></div>}
            <Textwrap>
            <h2>{project.title}</h2>
            {project._rawDescription &&
                <PortableText
                    value={project._rawDescription}
                    components={Serializers}
                />
            }
            </Textwrap>
            <>{project.image.map(item => (
                <figure key={item._key}>
                    <GatsbyImage image={item.image.asset.gatsbyImageData}  alt={item.caption ? item.caption : 'image'} data-fancybox="images"/>
                    <figcaption>
                      <h3>{item.caption}</h3>
                      {item._rawDescription &&
                      <PortableText
                      value={item._rawDescription}
                      components={Serializers}
                      />
                      }
                    </figcaption>
                </figure>
            ))}</>
        </Layout>
    )
}

export default ProjectTemplate 